import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

interface Patent {
  position: number;
  rank: number;
  patent_id: string;
  serpapi_link: string;
  title: string;
  snippet: string;
  abstract: string;
  priority_date: string;
  filing_date: string;
  grant_date: string;
  publication_date: string;
  inventor: string;
  assignee: string;
  publication_number: string;
  language: string;
  pdf: string;
  thumbnail?: string;
  images?: string[];
  figures?: string[];
  country_status: {
    [countryCode: string]: string;
  };
  errors?: string;
};
interface Publication {
  position: number | null,
  rank: number | null,
  scholar_id: string,
  serpapi_link: string,
  is_scholar: boolean,
  title: string,
  snippet: string,
  url_hostname: string,
  author: string,
  publication_date: string
};

interface Product {
  position: number,
  title: string,
  link: string,
  product_link: string,
  product_id: string,
  serpapi_product_api: string,
  number_of_comparisons: string,
  comparison_link: string,
  serpapi_product_api_comparisons: string,
  source: string,
  price: string,
  extracted_price: number,
  rating: number,
  reviews: number,
  extensions: string[],
  thumbnail: string,
  thumbnails: string[],
  tag: string,
  delivery: string,
}

interface DateObject {
  startDate: string;
  endDate: string;
}

interface Person {
  name: string;
}

interface Start {
  startDate: string;
  endDate: string;
}

interface JSONResponse {
  dateObj: DateObject;
  inventor: Person[];
  assignee: Person[];
  firstQury: string;
  advanchData: string;
  start: Start;
  patentOffice: string;
  language: string;
  inventorData: Person[];
  assigneeData: Person[];
  status: string;
  type: string;
  litigation: string;
  priority: string;
  selectedCheck: string[];
  promptId: string;
  featuresData: string;
  secondQuery: string;
  products: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  rerundownloadpublication:boolean;
  rerundownloadpatent:boolean;
  rerundownloadproduct:boolean;
  Haserrors:string;
  visibleImages:string[];
  errors?: string[]; 
  productError:boolean;
  productErrormessage:string;
  publicationerror:boolean;
  patenterrors:boolean;
  selectedTabs:  string[];
  girdView: boolean;
  selectResult: string;
  downReportModel: boolean;
  loading: boolean;
  patentSerpData: Patent[];
  caseid:string;
  publicationSerpData: Publication[];
  selectedPatentNumbers: string[];
  selectedPublicationNumbers: string[];
  selectedProductNumbers:string[];
  productDetails: Product[]
  searchId: number | null
  clearTrigger:boolean
  visibleGraphs: number[];
  overLappingData: any;
  errorOverLappingData:any;
  formattedData: any
  circularLoader:number[],
  neghbouringItemIndex: number[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class KeyFeatureLandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AdvancedSearchDetails),
      getName(MessageEnum.PatentDetails)
    ];

    this.state = {
      rerundownloadpublication:true,
      rerundownloadpatent:true,
      rerundownloadproduct:true,
      Haserrors:"",
      visibleImages:[],
      productErrormessage:"",
      productError:false,
      publicationerror:true,
      patenterrors:true,
      caseid :"",
      selectedTabs: ["Patent"],
      girdView: true,
      selectResult: "",
      downReportModel: false,
      loading: false,
      patentSerpData: [],
      publicationSerpData: [],
      selectedPatentNumbers: [],
      selectedPublicationNumbers:[],
      selectedProductNumbers:[],
      productDetails: [],
      searchId: null,
      clearTrigger:false,
      visibleGraphs: [],
      formattedData: [],
      overLappingData: [],
      errorOverLappingData:[],
      circularLoader: [],
      neghbouringItemIndex: [], 
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let createContractData = message.getData(getName(MessageEnum.AdvancedSearchDetails))
      if (createContractData) {
        this.patentAndPublicationSerpApi(createContractData)
        const product = createContractData?.selectedCheck?.includes('Products')
        if (product) {
          this.postProductDetails(createContractData)
        }
      }
    }

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const {
      apiProductDetailsCallID,
      regeneratePatentResultCallID,
      regenerateProductResultCallID,
      apiSerpCallID,
      apiSearchDetailCallID,
      apigetViewOverlappingDataCallID,
      apiGetPublicationListCallId,
      apiGetProductListCallId,
      apiGetPatentDownloadreportCallId,
      apiGetPublicationDownloadreportCallId,
      apiGetProductDownloadreportCallId,
      regeneratePublicationResultCallID,
    } = this;
    
    switch (apiRequestCallId) {
      case apiProductDetailsCallID:
        this.handleProductDetailsResponse(responseJson);
        break;
      case regeneratePatentResultCallID:
        this.regeratePatentResposne(responseJson);
        break;
      case regenerateProductResultCallID:
        this.regerateProductResposne(responseJson);
        break;
      case apiSerpCallID:
        this.handleSerpCallReceive(responseJson);
        break;
      case apiSearchDetailCallID:
        this.handleSearchDataReceive(responseJson);
        break;
      case apigetViewOverlappingDataCallID:
        this.handleViewOverlappingDataReceive(responseJson);
        break;
      case apiGetPublicationListCallId:
        this.handlePublicationReceive(responseJson);
        break;
      case apiGetProductListCallId:
        this.handleProductListReceive(responseJson);
        break;
      case apiGetPatentDownloadreportCallId:
        this.handlePatentDownloadReport(responseJson,this.state.rerundownloadpatent);
        break;
      case apiGetPublicationDownloadreportCallId:
        this.handlePublicationDownloadReport(responseJson,this.state.rerundownloadpublication);
        break;
      case apiGetProductDownloadreportCallId:
        this.handleProductDownloadReport(responseJson,this.state.rerundownloadproduct);
        break;
      case regeneratePublicationResultCallID:
        this.regeratePublicationResposne(responseJson);
        break;
      default:
        console.warn(`Unhandled apiRequestCallId: ${apiRequestCallId}`);
        break;
    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  apiSerpCallID: string = "";
  apiProductDetailsCallID: string = ""
  apiSearchDetailCallID: string = "";
  apigetViewOverlappingDataCallID: string = "";
  regeneratePatentResultCallID: string = "";
  regenerateProductResultCallID: string = "";
  apiGetPublicationListCallId: string = ""
  apiGetProductListCallId: string = ""
  apiGetProvisionalListCallId: string = ""
  apiGetPatentDownloadreportCallId: string = ""
  apiGetPublicationDownloadreportCallId: string = ""
  apiGetProductDownloadreportCallId: string = ""
  regeneratePublicationResultCallID:string="";
  circularLoaderIndex : number = -1;
  async componentDidMount() {
    const loginToken = await getStorageData("token")
    const selectResultValue = await getStorageData("selectResult")
    const parseselectResultValue = JSON.parse(selectResultValue);
    if (!loginToken) {
      this.goingToLogInPage()
    }
    let ListData = await getStorageData("BackTrue")
    if (ListData) {
      this.getSearchDetailDataApi()
    }
    let selectTab = await getStorageData("selectedCheck")
    let caseid = await getStorageData("caseid")
    let parsSelectTab = JSON.parse(selectTab)
    if (selectTab) {
      this.setState({ selectedTabs: parsSelectTab, selectResult: parseselectResultValue ? parseselectResultValue : parsSelectTab[0] },)
    }
    if (caseid) {
      this.setState({ caseid: caseid })
    }
    if (ListData && parsSelectTab.includes("Products")) {
      this.getProductListAPI()
    }

    if (ListData && parsSelectTab.includes("Publication")) {
      this.getPublicationListAPI()
    }
  }


  toggleImageVisibility = (publicationNumber: string, index : number) => {
    this.setNeghbouringItemIndex(index)
    this.setState((prevState) => {
      const isVisible = prevState.visibleImages.includes(publicationNumber);
      if (isVisible) {
        return {
          visibleImages: prevState.visibleImages.filter(id => id !== publicationNumber)
        };
      } else {
        return {
          visibleImages: [...prevState.visibleImages, publicationNumber]
        };
      }
    });
  };
  
  handleSerpCallReceive = (responseJson: { case_id: string, patents: any[], publications: any[], search_id: number, errors: [{ token: string }] }) => {
    if (responseJson.errors?.length) {
      this.setState({
        loading: false,
      }, () => {
        setStorageData("searchId", this.state.searchId)
        setStorageData("BackTrue", "BackTrue")
      })
    }
    const tokenExpire = responseJson.errors?.[0]?.token
    const resp: any = responseJson
    if (tokenExpire) {
      this.goingToLogInPage()
    }
    if (resp.patents.length >= 1 && resp.patents[0].errors) {
      this.setState({ patenterrors: false, loading: false ,Haserrors:resp.patents[0].errors,  })
    }
    if (resp.publications.length >= 1 && resp.publications[0].errors) {
      this.setState({ publicationerror: false, loading:false,    Haserrors:resp.publications[0].errors, })
    }
    if (responseJson) {     
      setStorageData("caseid", responseJson.case_id)
      this.setState({
        patentSerpData: responseJson?.patents || [],
        publicationSerpData: responseJson.publications || [],
        searchId: responseJson.search_id,
        loading: false,
        caseid: responseJson.case_id,
        patenterrors: !responseJson?.patents[0]?.errors,
        publicationerror: !responseJson?.publications[0]?.errors,
      })
    }
  
    setStorageData("searchId", responseJson.search_id)
    setStorageData("BackTrue", "BackTrue")
  }

  handleSearchDataReceive = (responseJson: { error: string, errors: string }) => {
    if (!responseJson.error && !responseJson.errors) {
      const res = (responseJson as any).slice(0, -1);
      this.setState({
        patentSerpData: res as unknown as Patent[],
        loading: false
      })
    }
    const resp: any = this.state.patentSerpData[0]
    if (resp.errors || resp.error) {
      this.setState({ patenterrors: false ,  Haserrors:responseJson.error||responseJson.errors ,loading: false
      })
    }

  }

  transformOverLappingData = () => {
    const formattedData = this.state.overLappingData.map((item: any) => {
      try {   
        let parsedMapping;

        if (Array.isArray(item.mapping) && item.mapping?.[0] !== '{') {
          parsedMapping = item.mapping;
        } else {
          let mappingString = item.mapping.join('');
          let parsed = JSON.parse(mappingString);
          if(parsed?.[0] == '{'){
            parsed = parsed.join('');
            parsedMapping = JSON.parse(parsed).mapping;
          } else parsedMapping = parsed.mapping
        }

         const formattedMapping = parsedMapping.map((entry: any) => {
           const [label, percentage] = entry.split(":");
           const value = parseFloat(percentage.replace("%", "").trim()) || 0;

            return {
              label: label.trim(),
              value: value 
            };
          });
          return {
            publication_number: item.publication_number,
            mapping: formattedMapping
          };
      } catch (error) {
        return {
          publication_number: item.publication_number,
          mapping: []
        };
      }
    });
    this.setState({ formattedData });
  };

  handleViewOverlappingDataReceive = (responseJson: any) => {
   
    if (!responseJson.errors && !responseJson.error) {
      this.setState((prevState) => ({
        overLappingData: [...prevState.overLappingData, responseJson],
        circularLoader: prevState.circularLoader.filter(
          (number) => number !== this.circularLoaderIndex
        ),
        loading: false
      }))
      this.transformOverLappingData()
    }
    else {
      const newError  = responseJson?.status == 500 ? 
        {
          message: "Something went wrong please try again later" ,
          index: this.circularLoaderIndex,
        } : responseJson?.errors?.[0];
      this.setState((prevstate)=>({
        loading: false,
        circularLoader: prevstate.circularLoader.filter(
          (number) => number !== this.circularLoaderIndex
        ),
        errorOverLappingData: [...prevstate.errorOverLappingData, newError],
      }))
    }
  }

  handleProductDetailsResponse = (responseJson: any) => {
    if (responseJson?.products) {
      setStorageData("caseid", responseJson.case_id)
      this.setState({
        productDetails: responseJson?.products,
        loading: false,
        productError: true,
        caseid:responseJson?.case_id
      })

    }
    else{
      this.setState({
        loading: false,
        patentSerpData: [],
        productError: false,
        // Haserrors:responseJson.error
      },()=>
      {
      });
    }
  }

  handlePublicationReceive = (responseJson: Publication[]) => {
      const publicationSerpData: any = responseJson.slice(0, -1); // All except the last item
      if (publicationSerpData.length > 0 && publicationSerpData[0].errors) {
        this.setState({ publicationerror: false,  Haserrors:publicationSerpData[0].errors , loading: false  });
      }
      else {
        this.setState({
          publicationSerpData,
          loading: false
        });
      }
  };

  handleProductListReceive = (responseJson: Product[]) => {
      if (responseJson) {
        this.setState({
          productDetails: responseJson,
          loading: false,
          productError:true
        })
      }
  }

  handlePatentDownloadReport = (responseJson: any,rerundownloadpatent:boolean) => {
      if (rerundownloadpatent && responseJson) {
        const selectpatents=this.state.selectedPatentNumbers;
        this.setState({ rerundownloadpatent: false }, () => {
          setTimeout(() => {this.getPatentDownloadreport(selectpatents);}, 3000); // 3 seconds delay
        });
      } else if (responseJson) {
        this.setState({
          selectedPatentNumbers: [],
          rerundownloadpatent: false
        });
      }
  };

  handlePublicationDownloadReport = (responseJson: any,rerundownloadpublication:boolean) => {
    if (rerundownloadpublication && responseJson) {
      const selectpublication=this.state.selectedPublicationNumbers;
      this.setState({ rerundownloadpublication: false }, () => {
        setTimeout(() => {this.getPublicationDownloadreport(selectpublication);}, 3000); // 3 seconds delay
      });
    } else if (responseJson) {
      this.setState({
        selectedPublicationNumbers: [],
        clearTrigger: true
      });
    }
  };

  handleProductDownloadReport = (responseJson: any,rerundownloadproduct:boolean) => {
    if(rerundownloadproduct && responseJson)
    {
      this.getProductDownloadReport(this.state.selectedProductNumbers)
      const selectproduct=this.state.selectedProductNumbers;
      this.setState({ rerundownloadproduct: false }, () => {
        setTimeout(() => {this.getProductDownloadReport(selectproduct);}, 3000); // 3 seconds delay
      });
    }    
    else if (responseJson) {
      this.setState({
        selectedProductNumbers: [],
        clearTrigger: true
      });
    }
  };

  onChangepatentselect = (publicationNumber: string) => {
    this.setState((prevState) => {
      const isAlreadySelected = prevState.selectedPatentNumbers.includes(publicationNumber);
      const updatedSelection = isAlreadySelected
        ? prevState.selectedPatentNumbers.filter((num) => num !== publicationNumber)
        : [...prevState.selectedPatentNumbers, publicationNumber];

      return { selectedPatentNumbers: updatedSelection };
    });
  };

  setNeghbouringItemIndex = (currentIndex : number) => {
    const nI = this.state.neghbouringItemIndex;
    const newIndex = currentIndex + (currentIndex % 2 == 0 ? 1 : -1);
    if(nI.includes(newIndex))
      this.setState({neghbouringItemIndex: nI.filter(i => i != newIndex)})
    else this.setState({neghbouringItemIndex: [... nI , newIndex]})    
  }

  toggleGraphVisibility = (publicationNumber: string, index : number) => {
    this.setNeghbouringItemIndex(index)
    this.setState((prevState) => {
      const isVisible = prevState.visibleGraphs.includes(index);
      if (isVisible) {
        return {
          visibleGraphs: prevState.visibleGraphs.filter(id => id !== index)
        };
      } else {
        return {
          visibleGraphs: [...prevState.visibleGraphs, index]
        };
      }
    });
    const hasOverlap = this.state.formattedData.find(
      (formattedItem: any) => formattedItem.publication_number === publicationNumber
    );
    if(!hasOverlap) {
      this.getViewOverlappingData(publicationNumber,index)
    }
  };
  
  goBackToLandingPage = async () => {
    removeStorageData("BackTrue")
    removeStorageData("prrovisonalid")
    removeStorageData("nonprrovisonalid")
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPageWeb"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  handleClickGridView = () => {
    this.setState(prevState => ({
      girdView: !prevState.girdView
    }));
  };

  getgridViewClassName = () => {
    return this.state.girdView
      ? "keyFeature-Overlapping-main-box-gridView"
      : "keyFeature-Overlapping-main-box";
  };
  
  getGridViewChartClassName() {
    return  this.state.girdView ? "keyFeature-chart-main-box-gridView" : "keyFeature-chart-main-box";
  }

  onClickSetStutus = (value: string) => {
    setStorageData("selectResult", JSON.stringify(value))
    this.setState({ selectResult: value })
    if(value === "Patent"){
     this.setState({selectedPublicationNumbers: [],selectedProductNumbers:[]})
    } else if(value === "Publication"){
      this.setState({selectedPatentNumbers: [],selectedProductNumbers:[]})
    }else if(value === "Products"){
      this.setState({selectedPatentNumbers: [], selectedPublicationNumbers:[]})
    }
  }

  downloadModelOpen = () => {
    this.setState({ downReportModel: true })
  };
  downloadDetails = () => {
    if ((this.state.selectedPatentNumbers.length > 0) || (this.state.selectedPublicationNumbers.length > 0) || (this.state.selectedProductNumbers.length > 0)) {
      this.setState({ clearTrigger: false })
      if (this.state.selectResult === "Patent") {
        this.downloadModelOpen()
        this.setState({rerundownloadpatent:true})
        this.getPatentDownloadreport(this.state.selectedPatentNumbers)
      } else if (this.state.selectResult === "Publication") {
        this.downloadModelOpen()
        this.setState({rerundownloadpublication:true})
        this.getPublicationDownloadreport(this.state.selectedPublicationNumbers)
      } else if (this.state.selectResult === "Products") {
        this.downloadModelOpen()
        this.setState({rerundownloadproduct:true})
        this.getProductDownloadReport(this.state.selectedProductNumbers)
      }
    }
  }

  downloadModelClose = () => {
    this.setState({ downReportModel: false })
  };

  goToShowMappingPage = async () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPageShowMapping"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  goingToLogInPage = async () => {
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    messageLogin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    messageLogin.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(messageLogin);
  };
  patentAndPublicationSerpApi = async (createContractData: JSONResponse) => {
    const header = {
      token: await getStorageData("token"),
    };

    this.setState({ loading: true });
    const valuesArray = createContractData?.inventorData?.map((obj: { name: string; }) => obj.name);
    const stringRepresentation = valuesArray.join(', ');

    const valuesAssignArray = createContractData?.assigneeData?.map((obj: { name: string; }) => obj.name);
    const stringAssign = valuesAssignArray.join(', ');

    const formData = new FormData();
    let isPatent = false
    let isPublication = false
    let isProducts = false
    let isnon_provisional = false
    let isprovisional = false
    let promptId = await getStorageData("promptId")
    let secondQuery = await getStorageData("secondQuery")
    if (createContractData?.selectedCheck && createContractData?.selectedCheck?.length) {
      isPatent = createContractData?.selectedCheck.includes("Patent");
      isPublication = createContractData?.selectedCheck.includes("Publication");
      isProducts = createContractData?.selectedCheck.includes("Products");
      isprovisional = createContractData?.selectedCheck.includes("Provisional Specification");
      isnon_provisional = createContractData?.selectedCheck.includes("Non-Provisional Specification");
    }
    let isLitigation = createContractData?.litigation === 'Has Related Litigation' ? "YES" : "NO"
    formData.append("query", createContractData?.firstQury);
    formData.append("second_query", secondQuery);
    formData.append("patents", JSON.stringify(isPatent));
    formData.append("publication", JSON.stringify(isPublication));
    formData.append("provisional", JSON.stringify(isprovisional));
    formData.append("non_provisional", JSON.stringify(isnon_provisional));

    promptId && formData.append("prompt_id", promptId);
    createContractData?.type && formData.append("type", createContractData?.type)
    isLitigation && formData.append("litigation", isLitigation)
    createContractData?.patentOffice.length !== 0 && formData.append("country", createContractData?.patentOffice)
    createContractData?.status && formData.append("status", createContractData?.status)
    createContractData?.language.length !== 0 && formData.append("language", createContractData?.language)
    stringRepresentation && formData.append("inventor", stringRepresentation)
    stringAssign && formData.append("assignee", stringAssign)
    createContractData?.dateObj?.endDate && formData.append("before", `${createContractData?.priority}:${createContractData?.dateObj?.endDate}`)
    createContractData?.dateObj?.startDate && formData.append("after", `${createContractData?.priority}:${createContractData?.dateObj?.startDate}`)
    formData.append("key_features", JSON.stringify(createContractData?.featuresData))
    formData.append("content", createContractData?.advanchData)
    isProducts && formData.append("products", JSON.stringify(isProducts))

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSerpCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.patentSerpApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  postProductDetails = async (createContractData: { firstQury: string }) => {
    const header = {
      token: await getStorageData("token"),
    };

    this.setState({ loading: true });

    const formData = new FormData();
    let promptId = await getStorageData("promptId")
    let secondQuery = await getStorageData("secondQuery")
    formData.append("query", createContractData?.firstQury);
    formData.append("second_query", secondQuery);
    promptId && formData.append("prompt_id", promptId);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiProductDetailsCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postProductAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSearchDetailDataApi = async () => {
    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token")
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSearchDetailCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSearchDetailAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getViewOverlappingData = async (publicationNumber: string,index: number) => {
    this.circularLoaderIndex = index;
    this.setState((prevState) => ({
      circularLoader: [...prevState.circularLoader, index],
    }));

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token")
    };
    const params = new URLSearchParams({
      publication_number: publicationNumber,
    }).toString()

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetViewOverlappingDataCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage2/mappings/overlaping_persentage?${params}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPublicationListAPI = async () => {
    console.log("1111111111111122222222getPublicationListAPI");
    
    this.setState({ loading: true })
    const header = {
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPublicationListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPublicationListAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  regeneratePatentResultApi = async () => {
    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.regeneratePatentResultCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.regenratePatentResultAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  regenerateProductResultApi = async () => {
    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token")
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.regenerateProductResultCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.regenrateProductResultAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  regeneratePublicationResultApi = async () => {
    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token")
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.regeneratePublicationResultCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.regenratePublicationResultAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  regerateProductResposne = async (responseJson: any) => {    
    if (responseJson?.errors?.length) {
      this.setState({
        loading: false,
        productError: false,
        productErrormessage: responseJson?.errors[0],
        Haserrors:responseJson?.errors[0],
      })
    } else if (responseJson?.length > 0 && responseJson[0]?.[0] === 'error') {
      this.setState({
        loading: false,
        Haserrors:responseJson[0]?.[1],
        productError:false,
        patentSerpData: []
      });
    }
     else if (responseJson?.length !== 0) {

      this.setState({
        loading: false,
        productError:true,
        productDetails: responseJson
      })

    }
    else {
      this.setState({
        loading: false,
        Haserrors:responseJson.error,
        productError:false,
        patentSerpData: []
      });
    }
  }

  regeratePublicationResposne = async (responseJson: any) => {
    if (responseJson?.publications) {
      this.setState({
        publicationSerpData: responseJson.publications,
        publicationerror: true,
        loading: false
      });
    }
    else {
      this.setState({
        publicationSerpData: [],
        publicationerror: false,
        loading: false,
        Haserrors:responseJson.error
      });
    }
  }
 

  regeratePatentResposne = async (responseJson: any) => {
      if (responseJson?.errors?.length) {
        const errorMessage = responseJson.errors[0].message;
        // Create a single Patent object with the error message
        const patentWithErrors: Patent = {
          position: 0,
          rank: 0,
          patent_id: '',
          serpapi_link: '',
          title: '',
          snippet: '',
          abstract: '',
          priority_date: '',
          filing_date: '',
          grant_date: '',
          publication_date: '',
          inventor: '',
          assignee: '',
          publication_number: '',
          language: '',
          pdf: '',
          country_status: {},
          errors: errorMessage
        };
    
        this.setState({
          Haserrors:responseJson.errors[0].message,
          loading: false,
          patentSerpData: [patentWithErrors],
          patenterrors: false
        });
      } else if (responseJson?.patents) {
        this.setState({
          loading: false,
          patentSerpData: responseJson.patents,
          patenterrors: true
        });
      } else {
        this.setState({
          loading: false,
          patentSerpData: [],
          patenterrors: false
        });
      }
  }

  getProductListAPI = async () => {
    this.setState({ loading: true })
    const header = {
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProductListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProductListAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setSelectedPublicationNumbers = (data: any) => {
    this.setState({
      selectedPublicationNumbers: data
    })
  }
  seTselectedProductNumbers = (data: any) => {
    this.setState({
      selectedProductNumbers: data
    })
  }


  getPatentDownloadreport = async (selectedPatentNumbers: any) => {
    const Body = { "publication_numbers": selectedPatentNumbers, "case_id": await getStorageData("caseid") };
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPatentDownloadreportCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_advanced_search/patent_download_report.pdf`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getPublicationDownloadreport = async (selectedPublicationNumbers: any) => {

    const Body = { "scholar_ids": selectedPublicationNumbers, "case_id": await getStorageData("caseid") };
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPublicationDownloadreportCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_advanced_search/publication_download_report`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getProductDownloadReport = async (selectedProductNumbers: any) => {
    const Body = {
      "product_ids": selectedProductNumbers,
      "case_id": await getStorageData("caseid")
    };
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProductDownloadreportCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_advanced_search/products_download_report`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getLoaderMessage() {
    return this.state.selectResult === "Provisional Specification" || this.state.selectResult === "Non-Provisional Specification" ? "...Generating Specification Details..." : "";
  }

  getColumnCount() {
    return this.state.girdView ? 6 : 12
  }

  getErrorOverlaping(item: Patent,index : number) {
    return this.state.errorOverLappingData.find(
      (entry: any) => entry?.publication_number === item?.publication_number || entry?.index === index
    );;
  }

  // Customizable Area End
}
