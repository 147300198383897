import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { checkAccountLimitError } from "../../../blocks/utilities/src/ResolveCondition";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { keyFeatureImg1, keyFeatureImg2, keyFeatureImg3, keyFeatureImg4, keyFeatureImg5, keyFeatureImg6 } from "./assets";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

interface ProducDetailsGridImages {
  image: string
};

interface ProductDetails {
  product_id: string,
  title: string,
  prices: string[],
  conditions: string[],
  reviews: number | null,
  rating: number | null,
  extensions: string[],
  description: string,
  media: [
      {
          type: string,
          link: string
      },
  ]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Startundefined
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  producDetailsGridViewImg: ProducDetailsGridImages[];
  loading: boolean;
  productDetails: ProductDetails
  isAccountLimitExceeded: boolean,
  isSimilarProducts:boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ProductDetailsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetProductDetailsCallId: string = ""
  apiGetDetailsCallId: string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    const isSimilarProducts = this.props.navigation.getParam("from") === 'similarProduct';

    this.state = {
      producDetailsGridViewImg: [
        { image: keyFeatureImg1 },
        { image: keyFeatureImg2 },
        { image: keyFeatureImg3 },
        { image: keyFeatureImg4 },
        { image: keyFeatureImg5 },
        { image: keyFeatureImg6 },
      ],
      loading: false,
      productDetails: {
        product_id: "",
        title: "",
        prices: [],
        conditions: [],
        reviews: null,
        rating: null,
        extensions: [],
        description: "",
        media: [
          {
            type: "",
            link: ""
          },
        ]
      },
      isAccountLimitExceeded: false,
      isSimilarProducts,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.apiGetProductDetailsCallId) {
      if(!responseJson?.errors?.length) {
        this.setState({productDetails: responseJson, loading: false})
      } 
      else {
        this.setState({loading: false,
          isAccountLimitExceeded:checkAccountLimitError(responseJson?.error,responseJson?.errors)
        })
      }
    } 
    if(apiRequestCallId === this.apiGetDetailsCallId) {
      if(!responseJson?.errors?.length && !responseJson?.errors) {
        this.setState({productDetails: responseJson, loading: false})
      }
      else{
        this.setState({loading:false,
          isAccountLimitExceeded:checkAccountLimitError(responseJson?.error,responseJson?.errors)
        })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const authToken = await getStorageData("token")
    if(!authToken){
      this.navigateToLogInPage()
    }
    const BackToDetails = await getStorageData("BackToDetails")
    if(BackToDetails) {
      this.getDetailsAPI()
    } else {
      const productId = this.props.navigation.getParam("id")
      setStorageData("publicationId", productId)    
      if(productId !== "N"){
        this.getProductDetailsAPI(productId)
      }
    }
  };
  getProductDetailsAPI = async (productId: string) => {
    this.setState({ loading: true })
    const header = {
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProductDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getProductDetailsAPIEndPoint}?product_id=${productId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDetailsAPI = async () => {
    this.setState({ loading: true })
    const header = {
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDetailsProductAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  navigateToLogInPage = async () => {
    const messageToLogin: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    messageToLogin.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    messageToLogin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    messageToLogin.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(messageToLogin);
  };
  goBackToProductPage = async () => {
    removeStorageData("BackToDetails")
    if(this.state.isSimilarProducts)
      return;
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "KeyFeatureLandingPageWeb"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  goToShowMappingPage = async () => {
     const data = {
      product: this.state.productDetails.product_id
  }
    setStorageData("productid",this.state.productDetails.product_id);
    setStorageData("isSimilarProducts",this.state.isSimilarProducts);
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductShowMapping");
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    )
    raiseMessage.addData(getName(MessageEnum.NavigationMessage), data);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message)
  };
  // Customizable Area End
}
